import general from '../inc/general';
import {ClientStatus, isClient, setClientCookie, TraderIframe} from '@cliotech-web/fxnet-iframe';
import {requestAnimationFrame} from 'CommonUtils/raf.js';
import { scrollToElem } from '../js/common/scroll';
const clientStatus = isClient();
const traderIframe = new TraderIframe('traderiframe', traderBaseUrl);
import { localSaveRaw, localLoadRaw, readcookie } from '../storageService';
import '../components/brokerChoiceModal/brokerChoiceModal';
import '../components/cookieConsentModal/cookieConsentModal';
import '../components/portuguesePopUpModal/portuguesePopUpModal';
import { logMessage } from '../js/common/logger';
import {addClickEventTracking} from '../js/common/clickEventTracking';
import { preventBodyScroll } from 'CommonUtils/preventBodyScroll.js';
import {changeAppLinksFunc} from 'CommonUtils/changeAppLinksFunc.js';
import {setActiveUrl} from 'CommonUtils/activeUrl.js';

window._broker = languageAttributes.broker;
const geoipcookie  = readcookie('geoip');
const eeaCountriesArr = ['AT', 'BG', 'HR', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'HU', 'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SK', 'SI', 'SE','AX','GF','PF','GP','MQ','YT','NC','RE','MF','SJ','WF'];
const changeAppLinks = languageAttributes.broker === '1' && 
                       !['ar'].includes(languageAttributes.languageCode) && 
                       eeaCountriesArr.includes(geoipcookie);


changeAppLinks && requestAnimationFrame(()=> changeAppLinksFunc());



if (clientStatus !== ClientStatus.Client && clientStatus !== ClientStatus.CookiesDenied) {
    
    traderIframe.createIframe(languageAttributes.broker*1, 'prod');

}



(function ($, dataLayer) {
    window.getparam = window['getparam'] ||
        function (name) {
            var re = new RegExp("[&\?]" + name + "=([^&]*)", "i");
            var m = document.location.search.match(re);
            return (m != null && m.length > 1) ? m[1] : "";
        };

    let initchat = false;

        if(!window.google_tag_manager){
            try {
                logMessage({eid: "gtm_not_loaded"});
            } catch (error) {
                console.error(error)
            }
        }
        function getUserGroup() {
            let userGroup = localStorage.getItem("userGroup");
            if (!userGroup) {
                userGroup = Math.random() < 0.5 ? "A" : "B";
                localStorage.setItem("userGroup", userGroup);
            }
            return userGroup;
        }

    window.windowOnLoad = function (callback) {
        if (window.addEventListener) {
            window.addEventListener('load', callback, false);
        } else if (window.attachEvent) {
            window.attachEvent('onload', callback);
        }

        let urlLocation = $('body').data('page-name');

         if(languageAttributes.languageCode === 'hi' && languageAttributes.spokenLanguageCode === 'en' && urlLocation !== 'iforex-ai') {

           let form = document.getElementById('form');
           let timeoutPopupLoaded = false;
           let popUpJumped = sessionStorage.getItem('timeOutPopUpShown') ? true : false ;

           let testing_user_group = getUserGroup();

           if( window.pageTracking && window.pageTracking.dsps ){
            window.pageTracking.dsps.push([3, testing_user_group === "A" ? "test_popup" : "control_nopopup"]);
            window.pageTracking.dsps.push([2, "Timeout_popup"]);
           }
            
           setTimeout(() => {
                
               if (!timeoutPopupLoaded && !popUpJumped && !form && testing_user_group === "A"){
                   timeoutPopupLoaded = true;
                       import(/* webpackChunkName: "time-out-popup" */ '../components/timeout-popup/timeout-popup')
                           .then(() => {
                           });
                   }

           }, "3000");

       }
    };

    window.savecookie = function (a, b, c) {
        var d = new Date();
        d.setTime(d.getTime() + 864E5 * ("undefined" != typeof c ? c : 3));
        c = "; expires\x3d" + d.toGMTString();
        document.cookie = a + "\x3d" + b + c + "; path\x3d/;domain\x3d" + document.domain.replace(/^[^\.]+/, '')
    };

    window.readcookie = function (a) {
        a = new RegExp("(?:^| )" + a + "\x3d([^;]*)", "i");
        try {
            let m = document.cookie.match(a);
            if (m && m.length === 2) {
                return m[1];
            }
        } catch(x){}
        return null;
    };


    window.openLightBox = function (name, type, length, url) {
        
        $('#totalPage').addClass('totalOn').fadeTo(260, 0.5, function () {
            
            $('#' + name + '').show();

            if (typeof type == "function"){
                type();
            } 
        });

        if (type === 'timeout') setTimeout(function () {
            closeLightBox(name)
        }, length);
        
        if (type === 'redirect') setTimeout(function () {
            window.location.href = url;
        }, length);

        if (type === 'loadframe') {
            $('#' + name + '').load(url);
        }

        preventBodyScroll();
    };

    window.closeLightBox = function (name, callback) {

        let elemEu = document.getElementById('cookie-notif-eu');

        $('#' + name + '').hide()
        
        if(name ==="pp_agreement" && elemEu && elemEu.style.display === 'block' ){
            
        }else{
            $('#totalPage').removeClass('totalOn');
        }


        
        if (typeof (callback) == "function") callback();

        preventBodyScroll();
    };

    let pp_content_elem = document.getElementById('pp_content');


    window.loadPrivacyLinkA = function (url) {
        let cpurl = url ? url : "/legal/cookie-policy.html";
        if(languageAttributes.broker === '3' && !url) cpurl = "/legal/privacy-policy-consent.html"

        logMessage({eid: "show_privacy_policy"});

        fetch(cpurl)
            .then(function(response) {
                return response.text();
            })
            .then(function(body) {
                pp_content_elem.innerHTML = body;
                window.openLightBox("pp_agreement",function(){
                    pp_content_elem.scrollTop = document.getElementById("cookie-section").offsetTop - 15;
                });
            });

        
    };

    window.resetScroll = function(){
        pp_content_elem.scrollTop = 0;
    }

 


    window.geoipcookie = window.readcookie('geoip');

    function replaceLoginUrl(url) {
        $('.btn-login').attr("href", url);
    }

    function replaceLangUrl(selector, url) {
        $(selector).attr("href", url);
    }

    if (window.geoipcookie === 'SA') {
        if(languageAttributes.spokenLanguageCode == 'ar') replaceLoginUrl("//trader.fihtrader.com/webpl3/Account/Login/Lang/Arabic");
        if(languageAttributes.spokenLanguageCode == 'en')replaceLoginUrl("//trader.fihtrader.com/webpl3/Account/Login/Lang/English");
        replaceLangUrl('.sa-link', "//www.iforex-saudi.com");
        replaceLangUrl('.en-link', "//www.iforexae.com");
    }

    
    if (window.geoipcookie === 'ID') {
        replaceLoginUrl("//trader.iforex.com/webpl3/Account/Login/Lang/Indonesian");
        replaceLangUrl('.en-link', "//www.iforexae.com");
    }

    

    


    window.scrollingTasks = [];


    window.__dlw = {
        rc: function (e) {
            var t = new RegExp("(?:^| )" + e + "=([^;]*)", "i"),
                i = document.cookie.match(t);
            return i && 2 === i.length ? decodeURIComponent(i[1]) : null;
        },
        sc: function (e, t, i) {
            var n = "";
            if (i) {
                var l = new Date();
                l.setTime(l.getTime() + 24 * (void 0 !== i ? i : 3) * 60 * 60 * 1e3), n = "; expires=" + l.toGMTString()
            }
            document.cookie = e + "=" + encodeURIComponent(t) + n + "; path=/;";
        },
        store: function (e, t, i) {
            i = i || 3;
            var n = {};
            if (n[e] = t, window.dataLayer.push(n), 3 !== i) {
                var l = window.__dlw.rc("cdl" + i),
                    r = [];
                if (null != l)
                    for (var c = (r = l.split(";")).length; c--;)
                        if (r[c].split(":")[0] === e) {
                            r.splice(c, 1);
                            break;
                        }
                r.push(e + ":" + t.replace(/;/g, ""));
                window.__dlw.sc("cdl" + i, r.join(";"), 1 == i && 1e3);
            }
        },
        init: function () {
            var e = (window.__dlw.rc("cdl1") || "") + ";" + (window.__dlw.rc("cdl2") || "");
            if (";" !== e) {
                let n;
                for (let t = e.split(";"), n = {}, i = t.length; i--;) "" !== t[i] && (n[t[i].split(":")[0]] = t[i].split(":")[1]);
                window.dataLayer.push(n);
            }
        }
    };

    


    window.jQuery('.btn-send').on('click', function () {

        var name = $('.mail_popup_name').val();

        var subject = $('.mail_popup_subject').val();

        var text = $('.mail_popup_textarea').val();

        if (subject != '') {
            if (languageAttributes.broker === '3'){
                window.location = 'mailto:cs@iforex.eu?subject=' + subject + '&body=Name:' + name + '%0D%0A%0D%0A' + text;
            }else{
                window.location = 'mailto:salesgroup@iforex.com?subject=' + subject + '&body=Name:' + name + '%0D%0A%0D%0A' + text;
            }
            

            closeLightBox("mail_popup");
            $('#mail_popup input, #mail_popup textarea').val('');
            $('.mail_popup_subject').css('border', 'none');
        } else {
            $('.mail_popup_subject').css('border', '1px solid red');
        }


    });


    window.jQuery('.toggle-title').on('click', function () {


        if (!$(this).parent().hasClass('active')) {
            $('.toggle-content').slideUp();
            $('.toggle-pannel').removeClass('active');
            $(this).parent().find('.toggle-content').slideDown();
            $(this).parent().addClass('active');

        } else {
            $('.toggle-content').slideUp();
            $('.toggle-pannel').removeClass('active');
        }

    });

    window.isInViewport = function (elem) {
        let elementTop = elem.offset().top;
        let elementBottom = elementTop + elem.outerHeight();

        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();

        //return elementBottom > viewportTop && elementTop < viewportBottom;
        return elementTop < viewportBottom;
    };

   

    function lazyImageBF() {
        var lazyImages = [].slice.call(document.getElementsByClassName("lazy-image-bf"));
        lazyImages.forEach.call(lazyImages, function (lazyImage) {
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove("lazy-image-bf");
        });
    }




    windowOnLoad(function () {
        let $ = window.jQuery;
        let main_header = $('.main-header');
        let body = $('body');
        let scrolling = false;
        let isBroker3 = languageAttributes.broker === '3';
        let fx_cn = languageAttributes.spokenLanguageCode === 'hi' ? 'fx_cn_hi' : 'fx_cn';
        let fx_cn_cookie = readcookie(fx_cn) == '1';

        function toggleCookieNotification() {

            if(!fx_cn_cookie){
                isBroker3 ? openLightBox('cookie-notif-eu') : $('#cookie-notif').show();
            }

            
        }

        window.savePPCookieA = function () {
            savecookie(fx_cn, "1");
            isBroker3 ? closeLightBox('cookie-notif-eu') : $('#cookie-notif').hide();

        };


        general();
        window.__dlw.init();

        // window.dataLayer.push({
        //     'gtm.start':
        //         new Date().getTime(), event: 'gtm.js'
        // });

        // const script = document.createElement('script');
        // document.body.appendChild(script);
        // script.async = true;
        // script.defer = true;
        // script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-${isBroker3 ? "MQZRMLM" : "KBZ4Z3H"}`;


        setActiveUrl(languageAttributes.languageCode);

        if (document.location.hash) {
            var hash = document.location.hash;
            try {
                if ($(hash) && $(hash).length > 0) {
                    $('html, body').animate({
                        scrollTop: $(hash).offset().top - main_header.height()
                    }, 1e3);
                }
            }
            catch (err) { }

        }

        if (languageAttributes.languageCode === 'es' && 
        languageAttributes.broker === '1' &&
        window.innerWidth < 992 && 
        window.innerWidth >= 768 ){

            const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            const iPad = /iPad/.test(navigator.userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
            
            if (safari && iPad) {
                let sourceElement = document.querySelectorAll('.badge-source');
                if(sourceElement){
                    sourceElement.forEach(element => {
                        element.srcset = 'https://content.webapi-services.net/shared/iforex/media/app_badges/apple_es.png'; 
                    });
                }
            }
            
        }


        /*******/
        toggleCookieNotification();


        if ($('.tabs-menu').length > 0) {
            $('.tabs-menu li').on('click', function () {
                $('.tabs-menu li, .tab-text').removeClass('active');
                $(this).addClass('active');
                var cat = $(this).data('title');

                $('[data-tab=' + cat + ']').addClass('active');
            });
        }


        let slidemenu = document.getElementById('slidemenu');

        if ($(window).width() < 992 && slidemenu != null)  {

             // scroll mob menu to bottom when clicking the "last" li , to reveal inner menu on small devices.
             

             const menuUlHeight = document.querySelector('.slidemenu-wrapper').offsetHeight + 86;
             let bottomBarHeight = document.getElementById('bottom-bar').offsetHeight;
             let viewHeight = window.innerHeight - bottomBarHeight;
             let viewDiff = menuUlHeight - viewHeight;
             let last2Menu = document.querySelector('.slidemenu-wrapper > li:nth-last-child(-n + 3) > .main-link');
             let lastMenu = document.querySelector('.slidemenu-wrapper > li:nth-last-child(-n + 2) > .main-link');
 
             if ((viewDiff) > 100) {
                 last2Menu.classList.add('last-scroll-bottom');
                 lastMenu.classList.add('last-scroll-bottom');
             }
 
             if ((viewDiff) > 0) {
                 lastMenu.classList.add('last-scroll-bottom');
             }
 
             document.querySelectorAll(".last-scroll-bottom").forEach(function (lastMenu) {
                 lastMenu.addEventListener("click", (e) => {
                     if (!(e.target.parentNode.classList.contains('open'))) {
                         scrollToElem(slidemenu, slidemenu.getBoundingClientRect().bottom, 500);
                     }
                 });
             });

        
            let dropdown_menu = $('.dropdown_menu_wrapper > .main-link');
            let inner_dropdown_menu = $('.inner_dropdown_menu_wrapper > .main-link');

            $('.lang_menu.dropdown_menu').removeClass('dropdown_menu');

            dropdown_menu.click(function () {

                if ($(this).parent().hasClass('open')) {
                    $(this).parent().find('.dropdown_menu, .inner_dropdown_menu').slideUp('slow');
                    $(this).parent().removeClass('open');
                } else {
                    $('.dropdown_menu, .inner_dropdown_menu').slideUp('slow');
                    $('.dropdown_menu_wrapper, .inner_dropdown_menu_wrapper').removeClass('open');
                    $(this).parent().find('.dropdown_menu').slideDown('slow');

                    $(this).parent().addClass('open');
                }
            });


            inner_dropdown_menu.click(function () {
                if ($(this).parent().hasClass('open')) {
                    $(this).parent().find('.inner_dropdown_menu').slideUp('slow');
                    $(this).parent().removeClass('open');
                } else {
                    $('.inner_dropdown_menu').slideUp('slow');
                    $('.inner_dropdown_menu_wrapper').removeClass('open');
                    $(this).parent().find('.inner_dropdown_menu').slideDown('slow');

                    $(this).parent().addClass('open');
                }
            });
        }


        $('.navbar-toggle, .close_mob_menu').click(function () {
            var options = { direction: 'left' };
            $('#slidemenu').toggleClass('open');
            if ($('#slidemenu').hasClass('open')) {
                $('#risk-floating').hide();
                document.body.classList.add("modal-open");

            } else {
                $('#risk-floating').show();
                document.body.classList.remove("modal-open");
            }
            if (!initchat) {
                window.dataLayer.push({ "event": "init-snapengage" });
                initchat = true;
            }
            $('.header-desk').toggle();

        });

        $('.select_lang, .lang_menu_close').click(function () {
            var options = { direction: 'bottom' };
            $('.lang_menu').toggleClass('open');
            $('#slidemenu').toggleClass('modal-open');

        });

         // ********** login popup ***********

        window.loginPopup = function() { 
            document.getElementById('login_popup').style.display='block';
            document.body.classList.add("modal-open");
        };

        window.loginPopupClose = function () {
            document.getElementById('login_popup').style.display='none';
            document.body.classList.remove("modal-open");
        };



        // ********** App stores links + QR modal START ***********
        if(languageAttributes.languageCode == 'es' && languageAttributes.broker == 1){
            const elQRContainer = document.querySelector('.qr-container');
            const elQRBtn = document.querySelector('.qr-btn');
            const elQRModal = document.querySelector('.qr-modal');
            let isQRModalOpen = false;


            const toggleQRModal = (e) => { 
                e.stopPropagation();
                const isModalClick = elQRModal.contains(e.target);
                if(!isModalClick){
                    isQRModalOpen = false;
                    document.body.removeEventListener('click', toggleQRModal);
                    requestAnimationFrame(()=> elQRContainer.classList.remove('open'));
                }
            }

            let qrChecked = false;

            const checkQR = () => {
                document.getElementById("appQR").style.display = 'none';
                document.getElementById("appQR360").style.display = 'block'
                qrChecked = true;
            }

            const setQRImg = () => {
                [document.getElementById("appQR"), document.getElementById("appQR360") ].forEach(el => {
                    el.src = el.dataset.src;
                });
                qrChecked = true;
            }

            const onQRModalBtnClick = (e) =>{

                !qrChecked && setQRImg();

                !qrChecked && changeAppLinks && checkQR();

                if(isQRModalOpen) return
                e.stopPropagation();
                isQRModalOpen = true; 
                document.body.addEventListener('click', toggleQRModal);
                requestAnimationFrame(()=> elQRContainer.classList.add('open'));

                try {
                    window.dataLayer.push({event: 'app_download_qr_click'})
                } catch(x) {}
            }


            elQRBtn && elQRBtn.addEventListener('click', onQRModalBtnClick);
        }

        // ********** App stores links + QR modal END ***********


        //Load Lazy images on scroll that are bellow the fold  with class lazy-image-bf
        let lazyImage_wrapper = $('.lazy-image-bf');
        let loadingLazyImagesBF = false, lazyImagesBFLoaded = false;
        let footer_wrapper = $('.footer-shared');
        let loadingFooter = false, footerLoaded = false;
        let cookie_notif = $('#cookie-notif');
        let cookie_notif_height = cookie_notif.outerHeight();
        let main_header_height = main_header.outerHeight();
        let fxEcalendarContainer = $('#fxEcalendar');
        let liveRatesContainer = $('#live-rates-widget');



        
        $(window).resize(function () {
            cookie_notif_height = cookie_notif.outerHeight();
            main_header_height = main_header.outerHeight();
        });

        let loadingfxEcalendar = false,
            fxEcalendarLoaded = false,
            loadingLiveRates = false,
            liveRatesLoaded = false;

        let animation_banner = $('.fixed-content'),
            social_bar = $('.li-social'),
            banner_height = $('.main-banner').height();
            
        const footer_support = document.querySelector(".footer-support");
        let footer_support_loaded = false;


        function doScrolling() {
            if (!loadingfxEcalendar && fxEcalendarContainer.length > 0 && fxEcalendarContainer.html().trim() === '' && window.isInViewport(fxEcalendarContainer)) {
                loadingfxEcalendar = true;
                !fxEcalendarLoaded && import(/* webpackChunkName: "fxEcalendar" */'../components/fxEcalendar/fxEcalendar')
                    .then(() => {
                        loadingfxEcalendar = false;
                        fxEcalendarLoaded = true;
                        window.fxEcalendar({ curr_lang: window.tm_language, importance: '3', maxRows: '6' });
                    });
            }

            if (!loadingLiveRates && liveRatesContainer.length > 0 && window.isInViewport(liveRatesContainer)) {

                loadingLiveRates = true;
                !liveRatesLoaded && import(/* webpackChunkName: "liveRatesWidget" */'../components/liveRatesWidget/liveRatesWidget.js')
                    .then(module => {
                        loadingLiveRates = false;
                        liveRatesLoaded = true;
                        module.default;
                    });
            }

            //Load Lazy images on scroll that are bellow the fold  with class lazy-image-bf

            if (lazyImage_wrapper.length > 0 && !loadingLazyImagesBF && !lazyImagesBFLoaded && window.isInViewport(lazyImage_wrapper)) {
                loadingLazyImagesBF = true;
                if (!lazyImagesBFLoaded) {
                    lazyImageBF();
                    loadingLazyImagesBF = false;
                    lazyImagesBFLoaded = true;
                }
            }
            if (footer_wrapper.length > 0 && !loadingFooter && window.isInViewport(footer_wrapper)) {


                loadingFooter = true;
                !footerLoaded && import(/* webpackChunkName: "footer" */'../components/footer/footer')
                    .then(module => {
                        loadingFooter = false;
                        footerLoaded = true;
                        module.default();
                        
                        

                        setTimeout(() => {
                            changeAppLinks && requestAnimationFrame(()=> changeAppLinksFunc());
                          }, "1000");
                    });
            }



            if ( !footer_support_loaded && document.querySelector(".footer-support") ){
                footer_support_loaded = true;
                if(['es','ar','cn','tw','ko','hi','nl','en','en_sa','de','el','en_eu','es_eu','fr','hu','it','pl','ru','kz','th'].includes(languageAttributes.languageCode)){
                    setTimeout(addClickEventTracking, 1000);

                }
            }


            if ($(window).width() > 992 ) {

                let scroll_top = $(document).scrollTop();
                let footer_offset_top = document.querySelector('.footer-shared') && document.querySelector('.footer-shared').offsetTop;
                let langSelector = ['es','ar','ko','cn','tw','nl','hi','en','en_sa','de','el','en_eu','es_eu','fr','hu','it','pl'].includes(languageAttributes.languageCode);
                
                if(langSelector && footer_offset_top &&  (scroll_top + window.innerHeight) > footer_offset_top){
                    social_bar.hide()
                }else {
                     social_bar.show()
                }

            }

            if (!initchat) {

                window.dataLayer.push({ "event": "init-snapengage" });
                initchat = true;

            }

        }

        

        window.scrollingTasks.push(doScrolling);

        if ($(window).width() > 992) {
            lazyImageBF();
            loadingLazyImagesBF = false;
            lazyImagesBFLoaded = true;
        }



        function scrollingTimer() {
            if ($(document).height() <= $(window).height()) {
                scrolling = true;
            }
            if ($(window).scrollTop() > 1) {
                scrolling = true;
            }
            if (window.callDoScrolling) scrolling = true;
            // if (scrolling) doScrolling();

            if (scrolling) window.scrollingTasks.forEach(function (task) {
                task();
            });
            window.setTimeout(scrollingTimer, 250);

            if (window.matchMedia("(min-width: 768px)").matches) {
                const stickyElem = document.querySelectorAll('.sticky-elem');
                const headerHeight = document.querySelector('.main-header').offsetHeight + 10;
            
                stickyElem.forEach(elem => {
                    elem.style.top = `${headerHeight}px`;
                });
            }
        }

        scrollingTimer();

        //window.dataLayer.push({"event":"tracking-init"});


        if ($(window).width() > 992 && (
            languageAttributes.spokenLanguageCode == 'es' ||
            languageAttributes.languageCode == 'hi' ||
            languageAttributes.spokenLanguageCode == 'ar') && !initchat) {
            initchat = true;
            window.dataLayer.push({ "event": "init-snapengage" });
        }

        window.startChat = function (t) {
            if (t === 'chat') {
                window.dataLayer.push({"event" : "start-livechat"});
                if(document.getElementById('chat-widget-container')){
                    document.getElementById('chat-widget-container').style.display = "block";
            }
            }
            if (typeof SnapEngage !== 'undefined') {
                window.dataLayer.push({ "event": "start-chat" });
                $('#SnapABug_WP').addClass('lightbox-login');
            } else {
                setTimeout(function () { window.startChat(); }, 1000);
            }

        };

        $(window).on('scroll resize', function (e) {
            scrolling = true;

        });

    });

    if(['es','ar','cn','tw','ko','hi','nl','en','en_sa','de','el','en_eu','es_eu','fr','hu','it','pl','ru','kz','th'].includes(languageAttributes.languageCode)){
        setTimeout(addClickEventTracking, 1000);
    }


    console.log('brotli compressed!')
})(window.jQuery, window.dataLayer || []);